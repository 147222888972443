<template>
  <div>
    <Telechargement />
    <div class="zone-bloc-1 wf-section" v-if="ready">
    <div class="bloc-dl-attestation w-container">
      <p class="paragraph titre-bloc">Bienvenue chez Pautions :)</p>
      <p class="paragraph titre-bloc _3"><span class="text-span-25">Téléchargez</span> votre attestation provisoire.</p>
      <a href="javascript:void(0)" @click="openAtt()" class="button w-button">cliquez ici</a>
    </div>
  </div>
  </div>
</template>
<script>
import Telechargement from '@/components/Menu/Telechargement';
export default {
  name: "FStep10",
  components: {
    Telechargement
  },
  data(){
    return {
      ready:false,
      url: null
    }
  },
  computed: {
  },
  watch:{
  },
  methods: {
    openAtt(){
       window.open(this.url, '_blank');
       setTimeout(() => {
         this.$router.push({path:'/accueil'})
       },50)
    }
  },
  mounted() {
    if(this.$route.query.uuid != undefined){
      this.url = `https://file.pautions.fr/tmp/${this.$route.query.uuid}.pdf`
      this.ready = true;
      this.$gtag.event('attestation_active', { method: 'attestation_active' });
    }
  },
  beforeCreate() {
    document.getElementsByTagName('body')[0].className = "body-crm";
  },
};
</script>
<style>
.bouton-paiement-type-rib{
  background-color: #242089;
  background-image: url(/img/ic_rib_blanc.56d983c4.svg);
  color: #fff;
}
.bouton-paiement-type-cb{
  background-color: #242089;
  background-image: url(/img/ic_cb_blanc.09fc00ab.svg);
  color: #fff;
}
.prix-mensuel-select{
  color: #fff;
  font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .w-layout-grid.grid-form {
    display: block !important;
  }
}
</style>
